<template>
  <div class="system-set">
    <div class="systemTitle">修改密码</div>
    <div class="table" v-if="isLogin">
      <div class="table-main">
        <div class="form-group form-inline" style="margin: 0 0 10px">
          <label for="oldPassword" style="margin-left: 25px">原密码</label>
          <input
            v-model="oldPassword"
            type="text"
            class="form-control"
            id="oldPassword"
            style="margin-left: 20px; width: 300px; background-color: white"
            placeholder="请输入原密码"
            @keyup="getOldPassword"
          />
          <div class="tooltips" v-show="isOld">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i
            >原密码输入错误
          </div>
          <div class="tooltips" v-show="isOldCheck">
            <i
              class="fa fa-check-circle fa-lg"
              style="color: #43a047"
              aria-hidden="true"
            ></i>
          </div>
        </div>

        <div class="form-group form-inline" style="margin: 20px 0 10px">
          <label for="newPassword" style="margin-left: 25px">新密码</label>
          <input
            v-model="newPassword"
            type="text"
            class="form-control"
            id="newPassword"
            style="margin-left: 20px; width: 300px; background-color: white"
            placeholder="请设置新密码"
            @keyup="getNewPassword"
          />
          <div class="tooltips" v-show="isNew">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i
            >新密码不能与原密码相同
          </div>
          <div class="tooltips" v-show="isNewCheck">
            <i
              class="fa fa-check-circle fa-lg"
              style="color: #43a047"
              aria-hidden="true"
            ></i>
          </div>
        </div>

        <div class="form-group form-inline" style="margin: 20px 0 10px">
          <label for="confirmPassword" style="margin-left: 10px"
            >确认密码</label
          >
          <input
            v-model="confirmPassword"
            type="text"
            class="form-control"
            id="confirmPassword"
            style="margin-left: 20px; width: 300px; background-color: white"
            placeholder="请确认新密码"
            @keyup="getConfirmPassword"
          />
          <div class="tooltips" v-show="isConfirm">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i
            >两次输入密码不一致
          </div>
          <div class="tooltips" v-show="isConfirmCheck">
            <i
              class="fa fa-check-circle fa-lg"
              style="color: #43a047"
              aria-hidden="true"
            ></i>
          </div>
        </div>

        <!-- <button type="button" class="btn btn-danger" @click="submit">
                提交
              </button> -->

        <el-button type="danger" @click="submit" round>提交</el-button>
        <el-button type="danger" plain round @click="reset">重置</el-button>
      </div>
    </div>

    <div class="login-text" v-else>
      <button type="button" class="btn btn-success" @click="goPage('Login')">
        登录
      </button>
    </div>
  </div>
</template>

<script>
import "../../assets/css/updatepsw.less";
import "../../assets/css/dashboard.less";
export default {
  data() {
    return {
      username: "",
      cust_id: "",
      cust_name: "",
      password: "",
      isLogin: false,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      isOld: false,
      isNew: false,
      isConfirm: false,
      isOldCheck: false,
      isNewCheck: false,
      isConfirmCheck: false,
      url: "http://beian.miit.gov.cn/",
    };
  },

  created() {
    this.getUserInfo();
    if (sessionStorage.getItem("user")) {
      this.isLogin = true;
    }
  },

  methods: {
    // 获取用户信息
    getUserInfo() {
      this.username = sessionStorage.getItem("user");
      this.cust_id = sessionStorage.getItem("cust_id");
      this.cust_name = sessionStorage.getItem("cust_name");
      this.password = window.atob(sessionStorage.getItem("password"));
    },

    goPage(name) {
      this.$router.push({ name });
    },

    // 退出登录
    logout() {
      if (this.cust_id == "261") {
        this.$router.push({ name: "CompanyLogin" });
      } else {
        this.$router.push({ name: "Login" });
      }
      sessionStorage.clear();
    },

    // 验证旧密码
    getOldPassword() {
      this.oldPassword = this.oldPassword.replace(/[^A-Za-z0-9]/g, "");
      if (this.oldPassword == "") {
        this.isOld = this.isOldCheck = false;
      } else if (this.oldPassword == this.password) {
        this.isOld = false;
        this.isOldCheck = true;
      } else if (this.oldPassword != this.password) {
        this.isOld = true;
        this.isOldCheck = false;
      } else {
        this.isOld = false;
      }
    },
    //验证新密码
    getNewPassword() {
      this.newPassword = this.newPassword.replace(/[^A-Za-z0-9]/g, "");
      if (this.newPassword == "") {
        this.isNew = this.isNewCheck = false;
      } else if (this.newPassword == this.oldPassword) {
        this.isNew = true;
        this.isNewCheck = false;
      } else {
        this.isNew = false;
        this.isNewCheck = true;
      }
    },

    //验证确认密码
    getConfirmPassword() {
      this.confirmPassword = this.confirmPassword.replace(/[^A-Za-z0-9]/g, "");
      if (this.confirmPassword == "") {
        this.isConfirm = this.isConfirmCheck = false;
      } else if (this.confirmPassword != this.newPassword) {
        this.isConfirm = true;
        this.isConfirmCheck = false;
      } else {
        this.isConfirm = false;
        this.isConfirmCheck = true;
      }
    },

    // 提交密码
    submit() {
      if (
        !this.oldPassword ||
        !this.newPassword ||
        !this.confirmPassword ||
        this.oldPassword != this.password ||
        this.newPassword == this.oldPassword ||
        this.confirmPassword != this.newPassword
      ) {
        return false;
      } else {
        this.axios({
          methods: "GET",
          url: "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx?",
          params: {
            ctype: "updatePsw",
            name: this.username,
            psw: this.oldPassword,
            Npsw: this.confirmPassword,
          },
        })
          .then((res) => {
            if ((res.data.row = 1)) {
              this.$message({
                showClose: true,
                message: "密码修改成功",
                type: "success",

                // message: h('p', null, [
                //   h('span', {style: 'font-size: 16px; color: red'}, '订单删除成功')
                // ])
              });
              this.$router.push({ name: "Login" });
              sessionStorage.clear();
            }
          })
          .catch((err) => {});
      }
    },

    // 重置密码
    reset() {
      this.oldPassword = this.newPassword = this.confirmPassword = "";
      this.isOld = this.isNew = this.isConfirm = false;
      this.isOldCheck = this.isNewCheck = this.isConfirmCheck = false;
    },

    goBean() {
      var r = confirm("您将要访问：http://beian.miit.gov.cn/，是否继续？");
      if (r == true) {
        window.location.href = this.url;
      }
    },

    handleOpen(key, keyPath) {},

    handleClose(key, keyPath) {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-button.is-round:not(.is-plain) {
  margin: 20px 20px 0 100px;
  outline: white;
  color: white;
}

::v-deep .el-button--danger.is-plain {
  margin: 20px 0 0;
  outline: white;
}
::v-deep .el-menu-item:focus,
.el-menu-item:hover,
.el-submenu__title:hover,
.el-submenu__title:focus {
  background-color: #fef2f0;
  color: #f5533d;
}

::v-deep .el-menu-item.is-active {
  color: #f5533d;
  background-color: #fef2f0;
}
::v-deep .el-submenu__title i:not(.el-icon-arrow-down) {
  margin-right: 22px !important;
  color: #f5533d !important;
}
::v-deep .el-submenu__title {
  font-size: 16px;
}
::v-deep .el-submenu .el-menu-item {
  font-size: 16px;
  padding: 0 0 0 60px !important;
}
</style>